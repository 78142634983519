import _export from "../internals/export";
import _functionUncurryThisClause from "../internals/function-uncurry-this-clause";
import _objectGetOwnPropertyDescriptor from "../internals/object-get-own-property-descriptor";
import _toLength from "../internals/to-length";
import _toString from "../internals/to-string";
import _notARegexp from "../internals/not-a-regexp";
import _requireObjectCoercible from "../internals/require-object-coercible";
import _correctIsRegexpLogic from "../internals/correct-is-regexp-logic";
import _isPure from "../internals/is-pure";
var $ = _export;
var uncurryThis = _functionUncurryThisClause;
var getOwnPropertyDescriptor = _objectGetOwnPropertyDescriptor.f;
var toLength = _toLength;
var toString = _toString;
var notARegExp = _notARegexp;
var requireObjectCoercible = _requireObjectCoercible;
var correctIsRegExpLogic = _correctIsRegexpLogic;
var IS_PURE = _isPure;
var slice = uncurryThis("".slice);
var min = Math.min;
var CORRECT_IS_REGEXP_LOGIC = correctIsRegExpLogic("endsWith");
// https://github.com/zloirock/core-js/pull/702
var MDN_POLYFILL_BUG = !IS_PURE && !CORRECT_IS_REGEXP_LOGIC && !!function () {
  var descriptor = getOwnPropertyDescriptor(String.prototype, "endsWith");
  return descriptor && !descriptor.writable;
}();

// `String.prototype.endsWith` method
// https://tc39.es/ecma262/#sec-string.prototype.endswith
$({
  target: "String",
  proto: true,
  forced: !MDN_POLYFILL_BUG && !CORRECT_IS_REGEXP_LOGIC
}, {
  endsWith: function endsWith(searchString /* , endPosition = @length */) {
    var that = toString(requireObjectCoercible(this));
    notARegExp(searchString);
    var endPosition = arguments.length > 1 ? arguments[1] : undefined;
    var len = that.length;
    var end = endPosition === undefined ? len : min(toLength(endPosition), len);
    var search = toString(searchString);
    return slice(that, end - search.length, end) === search;
  }
});
export default {};